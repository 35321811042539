// src/context/state.js
import { createContext, useContext } from "react";

const AppContext = createContext<any>(null);

export interface Props {
    children?: React.ReactNode;
    passedState?: any;
}

export function AppWrapper({ children, passedState }: Props) {
    const sharedState = {
        /* whatever you want */

        // configuration settings
        externalLinks: {
            lightricksWebsite: "https://www.lightricks.com/",
            facebook: "https://www.facebook.com/Lightricks.Global",
            instagram: "https://www.instagram.com/lightricks/",
            twitter: "https://twitter.com/Lightricks",
            linkedin: "https://www.linkedin.com/company/lightricks/",
            tiktok: "https://www.tiktok.com/@lightricks",
        },

        defaultAlt: "The247.com",

        sections: [] as any,
        setSections: () => {},

        selectedSection: {} as any,
        setSelectedSection: () => {},

        loading: false,
        setLoading: function (_loading: boolean): void {
            // console.log(`AppContext.setLoading _loading = ${_loading}`);
            this.loading = _loading;
        },

        ...passedState,
    };

    return (
        <AppContext.Provider value={sharedState}>
            {children}
        </AppContext.Provider>
    );
}

export function useAppContext(): any {
    return useContext(AppContext);
}
