import "../styles/globals.scss";
import "../styles/app.scss";
import "../styles/styles.scss";
import "../styles/media.scss";
import { ReactNode, useState } from "react";
import type { AppProps } from "next/app";
import { AppWrapper } from "./../context/state"; // import based on where you put it
import { NextPage } from "next";
import { Toaster } from "react-hot-toast";
import { setCookies, checkCookies } from "cookies-next";
import { useRouter } from "next/router";

type Page<P = {}> = NextPage<P> & {
    getLayout?: (page: ReactNode) => ReactNode;
};

type Props = AppProps & {
    Component: Page;
};

const App = ({ Component, pageProps }: Props) => {
    const [sections, setSections] = useState<any[]>([]);
    const [selectedSection, setSelectedSection] = useState<any[]>([]);
    const [suggestedArticles, setSuggestedArticles] = useState<any[]>([]);
    const [suggestedCreators, setSuggestedCreators] = useState<any[]>([]);

    // Variant
    // console.log("VARIANT!");
    const variant = 0.5 - Math.random() > 0 ? "a" : "b";
    const fallback_variant = variant === "a" ? "b" : "a";

    // Cookie
    const router = useRouter();
    const { key } = router.query;

    // Temp Blocking unless key is provided
    if (!checkCookies("validVisit")) {
        // Query Param
        if (key && key === "aae08af0-e301-4064-b522-8f6efaf7a0f3") {
            // valid visit
            setCookies("validVisit", true);
        } else {
            return (
                <h1 style={{ fontSize: "3rem", textAlign: "center" }}>
                    No Access
                </h1>
            );
        }
    }

    const value = {
        sections,
        setSections,
        selectedSection,
        setSelectedSection,
        variant,
        fallback_variant,
        suggestedArticles,
        setSuggestedArticles,
        suggestedCreators,
        setSuggestedCreators,
    };

    // If we will have special layout
    const getLayout = Component.getLayout ?? ((page: ReactNode) => page);

    return getLayout(
        <AppWrapper passedState={value}>
            <Toaster />
            <Component {...pageProps} />
        </AppWrapper>
    );
};
export default App;
